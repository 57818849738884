<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
            <button v-if="user.role == 'admin'" class="btn-secondary-outline mx-2 mt-3 sm:mt-0" @click.prevent="showSalesTarget()">
              {{ $t("staff_page.annual_sales_target") }}
              <BaseIcon icon="bullseye" class="ml-1" />
            </button>
            <router-link v-if="user.role == 'admin'" class="btn-green" to="/admin/skapa-staff">
              {{ $t("staff_page.new_staff") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="columns"
          :rows="staffs"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'type'">
              <span v-if="props.row.type == 'seller'">{{ $t("user_role.seller") }}</span>
              <span v-else-if="props.row.type == 'support'">{{ $t("user_role.support") }}</span>
              <span v-else-if="props.row.type == 'admin'">{{ $t("user_role.admin") }}</span>
              <span v-else-if="props.row.type == 'tech'">{{ $t("user_role.tech") }}</span>
            </span>
            <span v-else-if="props.column.field == 'edit' && user.role == 'admin'">
              <button class="btn-secondary-outline" @click.prevent="show(props.row)">
                {{ $t("edit") }}
                <BaseIcon icon="pencil-alt" class="ml-1" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'name'">
              <router-link v-if="props.row.type == 'seller' && user.role == 'admin'" class="text-primary-500 font-bold underline hover:text-primary-600 transition duration-200" :to="'/seller/' + props.row._id">
                {{ props.row.name }}
              </router-link>
              <span v-else>{{ props.row.name }}</span>
            </span>
            <span v-else-if="props.column.field == 'delete' && user.role == 'admin'">
              <button v-if="props.row.type != 'seller'" class="btn-red-outline" @click.prevent="deleteStaffPrompt(props.row)">
                {{ $t("delete") }}
                <BaseIcon icon="trash" class="ml-1" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <StaffUpdate :staffInfo="updateInfo" @updateStaff="getStaffs()" />
  </div>
</template>

<script>
import StaffUpdate from "@/components/modal/staff_update";

export default {
  title() {
    return `Personal | SecurCloud`;
  },
  components: {
    StaffUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      staffs: [],
      sales_goal: null,
      updateInfo: null,
    };
  },
  methods: {
    getStaffs() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/staffs`)
        .then((response) => {
          this.staffs = response.data.data;
          this.sales_goal = response.data.sales_goal;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    deleteStaffPrompt(staff) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Ta bort personal ${staff.name}?</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
            handler: () => {
              this.deleteStaff(staff);
            },
          },
        ],
      });
    },

    async deleteStaff(staff) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/staff/${staff._id}`);

        this.getStaffs();
        this.handleSuccess("Personal har tagits bort");

        this.$modal.hide("dialog");
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    exportXLS() {
      let export_columns = [];

      this.staffs.forEach((staff) => {
        export_columns.push({
          Type: staff.type,
          Namn: staff.name,
          Email: staff.email,
          Telefon: staff.phone,
          Födelsedatum: this.formatDate(staff.birth_date),
        });
      });

      this.excelExport(export_columns, "Personal", "personal_rapport");
    },

    show(staff) {
      this.updateInfo = _.cloneDeep(staff);
      this.$modal.show("update-staff");
    },

    showSalesTarget() {
      this.updateInfo = _.cloneDeep(this.sales_goal);
      this.$modal.show("update-staff");
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    columns() {
      return [
        { label: this.$t("staff_page.type"), field: "type", sortable: false },
        { label: this.$t("staff_page.name"), field: "name", sortable: false },
        { label: this.$t("staff_page.email"), field: "email", sortable: false },
        { label: this.$t("staff_page.phone"), field: "phone", sortable: false },
        { label: this.$t("staff_page.birth_date"), field: "birth_date", sortable: false, formatFn: this.formatDate },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ];
    },
  },
  created() {
    this.getStaffs();
    this.setPageTitle(`Personal`, "staffs");
  },
};
</script>
