<template>
  <modal name="update-staff" class="modal-inner modal-update-staff" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('update-staff')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateStaff" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.type" :options="staff_type" nameSelector="name" optionSelector="id" field_name="Typ" rules="required" readonly disabled />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="updateInfo.type != 'sales_goal'"></div>
        <div class="flex flex-wrap" v-if="updateInfo.type != 'sales_goal'">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.name" type="text" field_name="Namn" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.email" type="text" field_name="Email" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.phone" type="text" field_name="Telefon" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <ValidationProvider name="Födelsedatum" rules="" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Födelsedatum</label>
                <date-picker :lang="date_picker_lang" :key="date_picker_langKey" class="mt-2" v-model="updateInfo.birth_date" value-type="format" format="YYYY-MM-DD"></date-picker>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="updateInfo.type != 'sales_goal' && updateInfo.type != 'tech'"></div>
        <div class="flex flex-wrap" v-if="updateInfo.type != 'sales_goal' && updateInfo.type != 'tech'">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.bank_name" type="text" field_name="Bank" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.bank_identifier" type="text" field_name="ICE" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.bank_account_number" type="text" field_name="Kontonummer" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="user.role == 'admin' && (updateInfo.type == 'seller' || updateInfo.type == 'sales_goal')"></div>
        <div class="flex flex-wrap" v-if="user.role == 'admin' && (updateInfo.type == 'seller' || updateInfo.type == 'sales_goal')">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model.number="updateInfo.yearly_budget_alarm" type="number" field_name="Årligt budgetlarm" rules="required" @change="divideMonthlyAlarms()" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model.number="updateInfo.yearly_budget_revenue" type="number" field_name="Årliga budgetintäkter (SEK)" rules="required" @change="divideMonthlyRevenue()" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="updateInfo.type == 'sales_goal'">
            <BaseInput v-model.number="updateInfo.seller_number" type="number" field_name="Antal säljare" rules="required" />
          </div>
          <div class="w-full md:w-2/3 px-2 py-2 bg-primary-50">
            <div class="w-full text-sm font-bold mb-4">Månatlig<b class="text-red-500 ml-1">*</b></div>
            <div class="w-full flex flex-wrap mb-4">
              <div class="w-1/3 my-auto text-sm font-bold">Månad</div>
              <div class="w-1/3 my-auto px-2 text-sm font-bold">Budgetlarm</div>
              <div class="w-1/3 my-auto px-2 text-sm font-bold">Budgetintäkter (SEK)</div>
            </div>
            <div class="w-full flex flex-wrap my-1 border-b border-gray-300" v-for="m in updateInfo.monthly_budget" :key="m.id">
              <div class="w-1/3 text-sm my-auto">
                {{ m.name }}
              </div>
              <div class="w-1/3 my-auto px-2">
                <ValidationProvider :name="m.name" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <input type="number" v-model.number="m.alarms" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" @change="calculateYearlyAlarms()" />
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="w-1/3 my-auto px-2">
                <ValidationProvider :name="m.name" rules="required" v-slot="{ classes, errors }">
                  <div class="input-validate" :class="classes">
                    <input type="number" min="0" v-model.number="m.revenue" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" @change="calculateYearlyRevenue()" />
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-staff')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateStaff()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  props: {
    staffInfo: {
      required: true,
      default: null,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      user: this.$store.state.user,
      updateInfo: null,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
      staff_type: [
        { id: "seller", name: "Säljare" },
        { id: "support", name: "Support" },
        { id: "admin", name: "Admin" },
        { id: "tech", name: "Tekniker" },
        { id: "sales_goal", name: "Årligt försäljningsmål" },
      ],
      monthly_budget: [
        { id: 1, name: "Januari", alarms: 0, revenue: 0 },
        { id: 2, name: "Februari", alarms: 0, revenue: 0 },
        { id: 3, name: "Mars", alarms: 0, revenue: 0 },
        { id: 4, name: "April", alarms: 0, revenue: 0 },
        { id: 5, name: "Maj", alarms: 0, revenue: 0 },
        { id: 6, name: "Juni", alarms: 0, revenue: 0 },
        { id: 7, name: "Juli", alarms: 0, revenue: 0 },
        { id: 8, name: "Augusti", alarms: 0, revenue: 0 },
        { id: 9, name: "September", alarms: 0, revenue: 0 },
        { id: 10, name: "Oktober", alarms: 0, revenue: 0 },
        { id: 11, name: "November", alarms: 0, revenue: 0 },
        { id: 12, name: "December", alarms: 0, revenue: 0 },
      ],
    };
  },
  watch: {
    staffInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      if (this.updateInfo.type == "seller" && this.updateInfo.monthly_budget.length == 0) {
        this.updateInfo.monthly_budget = _.cloneDeep(this.monthly_budget);
      }
    },
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
  methods: {
    updateStaff() {
      this.$refs.formUpdateStaff.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          type: this.updateInfo.type,
          name: this.updateInfo.name,
          phone: this.updateInfo.phone,
          email: this.updateInfo.email,
          birth_date: this.updateInfo.birth_date,
          yearly_budget_alarm: this.updateInfo.yearly_budget_alarm,
          yearly_budget_revenue: this.updateInfo.yearly_budget_revenue,
          monthly_budget: this.updateInfo.monthly_budget,
          bank_name: this.updateInfo.bank_name,
          bank_identifier: this.updateInfo.bank_identifier,
          bank_account_number: this.updateInfo.bank_account_number,
        };

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/staff/${this.updateInfo._id}`, data)
          .then(() => {
            // eslint-disable-line
            this.$emit("updateStaff");
            this.$modal.hide("update-staff");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    divideMonthlyAlarms() {
      let d = this.distributeMonthly(this.updateInfo.yearly_budget_alarm);

      for (let i = 0; i < d.length; i++) {
        let monthlyAlarms = d[i];
        this.updateInfo.monthly_budget[i].alarms = monthlyAlarms;
      }
    },

    divideMonthlyRevenue() {
      let d = this.distributeMonthly(this.updateInfo.yearly_budget_revenue);

      for (let i = 0; i < d.length; i++) {
        let monthlyRevenue = d[i];
        this.updateInfo.monthly_budget[i].revenue = monthlyRevenue;
      }
    },

    distributeMonthly(val) {
      let distributeInteger = function*(total, divider) {
        if (divider === 0) {
          yield 0;
        } else {
          let rest = total % divider;
          let result = total / divider;

          for (let i = 0; i < divider; i++) {
            if (rest-- > 0) {
              yield Math.ceil(result);
            } else {
              yield Math.floor(result);
            }
          }
        }
      };

      let d = [];
      for (let m of distributeInteger(val, 12)) {
        d.push(m);
      }

      return d;
    },

    calculateYearlyAlarms() {
      let monthlyAlarms = _.sumBy(this.updateInfo.monthly_budget, function(o) {
        return parseInt(o.alarms);
      });
      this.updateInfo.yearly_budget_alarm = monthlyAlarms;
    },

    calculateYearlyRevenue() {
      let monthlyRevenue = _.sumBy(this.updateInfo.monthly_budget, function(o) {
        return parseInt(o.revenue);
      });
      this.updateInfo.yearly_budget_revenue = monthlyRevenue;
    },

    hide(id) {
      this.$refs.formUpdateStaff.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
